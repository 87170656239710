import { camelCase } from "lodash-es"
import { SchemaOf, ValidationError } from "yup"

import { isNil } from "./any"

export function validate<C>(schema: SchemaOf<C>, obj: C) {
  try {
    schema.validateSync(obj, { abortEarly: false })
  } catch (e) {
    if (e instanceof ValidationError) {
      return e?.inner ?? [e]
    } else {
      throw e
    }
  }

  return []
}

/**
 * Recursively applies `fn` to all keys in a object returning a new object. Warning: This is an expensive function. Avoid
 * using repeatedly, and cache results when possible.
 * @param fn
 * @param obj
 * @returns a new object
 */
export function deepTransformKeys(fn: (key: string, value: any) => string, obj: Record<string, any>) {
  const newObj: typeof obj = {}

  if (typeof obj !== "object") return obj

  for (const key in obj) {
    const val = obj[key]
    const newKey = fn(key, val)

    if (isNil(val)) {
      newObj[newKey] = val
      continue
    }

    const { constructor } = Object.getPrototypeOf(val)

    newObj[newKey] =
      constructor === Object
        ? deepTransformKeys(fn, val)
        : constructor === Array
          ? val.map((x) => deepTransformKeys(fn, x))
          : val
  }

  return newObj
}

export function camelCaseKeys(obj: Record<string, any>) {
  return deepTransformKeys(camelCase, obj)
}

export type RecordPredicate<V, K extends keyof any> = (val: V, key: K, obj: Record<K, V>) => boolean
export function filterValues<K extends keyof any, V>(fn: RecordPredicate<V, K>, obj: Record<K, V>) {
  const newObj = {} as Record<K, V>

  for (const key in obj) {
    if (fn(obj[key], key, obj) === true) newObj[key] = obj[key]
  }

  return newObj
}

export function permitKeys<K extends keyof any, V>(obj: Record<K, V>, keys: K[]): Record<K, V> {
  return keys.reduce(
    (acc, key) => {
      acc[key] = obj[key]
      return acc
    },
    {} as Record<K, V>
  )
}

export type RecordValueMapper<V, K extends keyof any> = (val: V, key: K, obj: Record<K, V>) => V
export function mapValues<V, K extends keyof any>(fn: RecordValueMapper<V, K>, obj: Record<K, V>) {
  const newObj = {} as Record<K, V>

  for (const key in obj) {
    newObj[key] = fn(obj[key], key, obj)
  }

  return newObj
}

export type RecordKeyMapper<V, K extends keyof any> = (val: V, key: K, obj: Record<K, V>) => K
export function mapKeys<V, K extends keyof any>(fn: RecordKeyMapper<V, K>, obj: Record<K, V>) {
  const newObj = {} as Record<K, V>

  for (const key in obj) {
    newObj[fn(obj[key], key, obj)] = obj[key]
  }

  return newObj
}

export type RecordMapper<V, K extends keyof any> = (val: V, key: K, obj: Record<K, V>) => [K, V]

export function mapPairs<V, K extends keyof any>(fn: RecordMapper<V, K>, obj: Record<K, V>) {
  const newObj = {} as Record<K, V>

  for (const key in obj) {
    const [newKey, newValue] = fn(obj[key], key, obj)
    newObj[newKey] = newValue
  }

  return newObj
}

export function firstKey<V, K extends keyof any>(obj: Record<K, V>): null | K {
  for (const key in obj) {
    return key
  }
  return null
}

export function firstValue<V, K extends keyof any>(obj: Record<K, V>): null | V {
  for (const key in obj) {
    return obj[key]
  }
  return null
}

export type RecordEach<V, K extends keyof any> = (val: V, key: K, obj: Record<K, V>) => unknown
export function forEachGivenKey<V, K extends keyof any>(fn: RecordEach<V, K>, obj: Record<K, V>, keys: K[]) {
  for (const key of keys) {
    fn(obj[key], key, obj)
  }
}
